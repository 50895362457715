import { Anchor, Card, CardBody, CardHeader, Image, Text } from "grommet";
import styled from "styled-components";

interface Media {
  name: string;
  url: string;
}

export interface Event {
  media: Media[];
  place: {
    address: string;
    name: string;
  };
  slug: string;
  start_datetime: number;
  tags: string[];
  title: string;
  uuid: string;
}

const StyledAnchor = styled(Anchor)`
  &:hover,
  &:active {
    outline: none;
    text-decoration: none;

    > div {
      background-color: #494949;
    }

    img {
      opacity: 0.5;
    }
  }
`;

const StyledImage = styled(Image)`
  max-height: 200px;
`;

const EventCard = ({
  media,
  place,
  slug,
  start_datetime,
  tags,
  title,
}: Event) => {
  const startDate = new Date(start_datetime);

  return (
    <StyledAnchor
      href={`https://eventos.coletivos.org/event/${slug}`}
      margin={{ bottom: "large" }}
      target="_blank"
    >
      <Card elevation="medium" background="dark-2">
        <CardHeader>
          <StyledImage
            fit="cover"
            src={`https://eventos.coletivos.org/media/thumb/${media?.[0].url}`}
          />
        </CardHeader>
        <CardBody pad="medium" gap="small">
          <Text size="xsmall">
            {startDate.toDateString()}
            {" - "}
            {startDate.toLocaleTimeString(undefined, {
              timeStyle: "short",
            })}
          </Text>
          <Text color="brand">{title}</Text>
          <Text size="small">
            {place.name} - {place.address}
          </Text>

          <Text color="dark-3" size="small">
            {tags.map((tagItem) => `#${tagItem} `)}
          </Text>
        </CardBody>
      </Card>
    </StyledAnchor>
  );
};

export default EventCard;
