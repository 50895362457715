import { Anchor, Card, CardBody, Text } from "grommet";
import styled from "styled-components";
import theme from "../../theme";
import Button from "../Button";

const StyledAnchor = styled(Anchor)`
  &:hover,
  &:active {
    outline: none;
    text-decoration: none;

    > div {
      background-color: #494949;
    }
  }
`;

const Title = styled(Text)`
  text-transform: uppercase;
`;

const ContentCard = ({
  color = "brand",
  description,
  tag,
  title,
  url,
}: {
  color: string;
  description: string;
  tag: string;
  title: string;
  url: string;
}) => (
  <StyledAnchor href={url} target="_blank" margin={{ bottom: "large" }}>
    <Card elevation="medium" background="dark-2">
      <CardBody pad="medium" gap="small">
        <Title color={color} weight="bold" size="xlarge">
          {title}
        </Title>
        <Text size="small">{description}</Text>
        <div>
          <Button
            secondary
            style={{
              border: `1px solid ${theme.global?.colors?.[color] || "white"}`,
            }}
            color={color}
            href="url"
            target="_blank"
          >
            {tag}
          </Button>
        </div>
      </CardBody>
    </Card>
  </StyledAnchor>
);

export default ContentCard;
