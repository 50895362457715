import Home from "./containers/Home";
import theme from "./theme";
import Navbar from "./components/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box, Grommet, Main } from "grommet";
import styled from "styled-components";

const StyledMain = styled(Main)`
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
`;

const App = () => (
  <Grommet theme={theme} plain>
    <StyledMain>
      <Navbar />

      <Box pad={{ left: "large", right: "large" }}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </StyledMain>
  </Grommet>
);

export default App;
