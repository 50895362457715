import { ThemeType } from "grommet";

const theme: ThemeType = {
  global: {
    colors: {
      brand: "#05c026",
      "dark-1": "#2e2e2e",
      "dark-2": "#262626",
      blue: "#1bbaff",
      yellow: "#FFCA58",
      pink: "#FF078D",
      orange: "#f8692f",
      grey: "#989898",
    },
    font: {
      family: "liberation_monoregular",
      size: "18px",
      height: "20px",
    },
  },
};

export default theme;
