import { Header, Image } from "grommet";
import styled from "styled-components";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 34px;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
`;

const Link = styled.a`
  align-items: center;
  color: #fff;
  display: inline-flex;
  text-decoration: none;
`;

const Preffix = styled.span`
  color: #05c026;
  display: inline-flex;
  margin-right: 8px;
  margin-left: 24px;
`;

const Logo = styled(Image)`
  max-height: 60px;
`;

const Blink = styled.span`
  background-color: #fff;
  display: inline-flex;
  height: 32px;
  margin-left: 16px;
  width: 12px;
  animation: blink 1s step-start 0s infinite;

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
`;
const Menu = styled.div`
  color: #05c026;
  font-size: 17px;
`;
/*

const MenuLink = styled.a<{ active?: boolean }>`
  color: #05c026;
  display: inline-flex;
  font-size: 16px;
  margin: 0 16px;
  text-decoration: none;
  text-transform: uppercase;

  ${(props) =>
    props.active &&
    css`
      text-decoration: underline;
    `}

  :hover {
    color: #fff;
    text-decoration: underline;
  }
`;
*/
const Navbar = () => (
  <Header height="130px" pad="large">
    <Wrapper>
      <Link href="https://coletivos.org">
        <Logo src={"/logo.jpg"} />
        <Preffix>{">"}</Preffix>
        coletivos.org
        <Blink />
      </Link>

      <Menu>
        {/**

        <MenuLink active={false} href="/">
          Sobre
        </MenuLink>
        |
        <MenuLink active href="/">
          Colaboradoras
        </MenuLink>
      */}
      </Menu>
    </Wrapper>
  </Header>
);

export default Navbar;
