const config = {
  description: {
    pt: "Nós somos um coletivo que fornece espaço a outros coletivos na internet, para debater, partilhar, conversar, mail e uma rede social! Queremos um espaço na internet onde sejamos soberanos dos nossos dados e das nossas redes, sendo a confiança criada através de provas de cooperação.",
    en: "We are a colective that gives to other colectives a internet link to the fediverse, with all the tools to debate, share, conversation, e-mail, video-tube and a social network! All this on our colective server! We want a internet where we are owners of our data, our network. Trust is given by proof of cooperation. Join us, if you can and want, to donate.",
  },
  events: {
    apiEndpoint:
      "https://eventos.coletivos.org/api/events?max=3&show_recurrent=true",
    name: "agenda de eventos livres e inclusivxs",
    seeMore: "Ver todos",
    url: "https://eventos.coletivos.org/",
  },
  videos: {
    apiEndpoint:
      "https://videos.coletivos.org/api/v1/videos/?filter=local&nfsw=false&sort=-createdAt&count=4",
    name: "Vídeos de coletivos e amigas",
    url: "https://videos.coletivos.org/",
    seeMore: "Ver mais vídeos",
  },
  socialNetworks: {
    name: "redes sociais",
    items: [
      {
        title: "Mastodon",
        description:
          "Junta-te a nós no Mastodon, a tua porta para a #fediverse",
        tag: "social.coletivos.org",
        url: "https://social.coletivos.org/",
        color: "blue",
      },
      {
        title: "Vídeos",
        description:
          " Peertube é onde podes partilhar os teus vídeos no #fediverse",
        tag: "videos.coletivos.org",
        url: "https://videos.coletivos.org/",
        color: "yellow",
      },
      {
        title: "Agenda",
        description:
          "Agenda, marca os teus eventos numa agenda de código livre e federada.",
        tag: "eventos.coletivos.org",
        url: "https://eventos.coletivos.org/",
        color: "orange",
      },
    ],
  },
  tools: {
    name: "ferramentas para coletivas",
    items: [
      {
        title: "Escrever",
        description: "Editor de texto para colaboração em tempo real",
        tag: "escrever.coletivos.org",
        url: "https://escrever.coletivos.org/",
        color: "pink",
      },
      {
        title: "Cloud + Email",
        description:
          "Se precisares de uma cloud para o teu coletivo+e-mail, contata-nos fisicamente.",
        tag: "cloud.coletivos.org",
        url: "https://cloud.coletivos.org/",
        color: "brand",
      },
      {
        title: "Forúm",
        description:
          "Se precisares de um forúm de discussão para o teu coletivo, contata-nos fisicamente.",
        tag: "forum.coletivos.org",
        url: "https://forum.coletivos.org/",
        color: "grey",
      },
    ],
  },
  footer: {
    name: "contata-nos",
    items: [
      { href: "https://social.coletivos.org/@suporte", name: "Mastodon" },
      { href: "mailto:suporte@coletivos.org", name: "E-mail" },
      { href: "xmpp:coletivos@chat.disroot.org?join", name: "XMPP" },
      { href: "https://t.me/coletivosorg", name: "Telegram" },
    ],
  },
};

export default config;
