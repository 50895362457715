import { Anchor, Box, Image, Text } from "grommet";
import styled from "styled-components";

export interface VideoType {
  account: {
    name: string;
  };
  name: string;
  previewPath: string;
  publishedAt: string;
  url: string;
  uuid: string;
}

const Wrapper = styled.div``;

const Info = styled(Box)`
  position: absolute;
  bottom: 64px;
  left: 32px;
  min-width: 40%;
`;

const StyledAnchor = styled(Anchor)`
  position: relative;

  &:hover,
  &:active {
    outline: none;
    text-decoration: none;

    div {
      background-color: #494949;
    }

    img {
      opacity: 0.5;
    }
  }
`;

const StyledImage = styled(Image)`
  max-height: 550px;
  width: 100%;
`;

const VideoCard = ({
  account,
  name,
  previewPath,
  publishedAt,
  url,
}: VideoType) => {
  const publishedDate = new Date(publishedAt);

  return (
    <Wrapper>
      <StyledAnchor href={url} margin={{ bottom: "large" }} target="_blank">
        <StyledImage
          fit="cover"
          src={`https://videos.coletivos.org${previewPath}`}
        />
        <Info>
          <Box elevation="large" background="dark-2" pad={"small"}>
            <Text size="xsmall">
              {publishedDate.toLocaleDateString()}{" "}
              {publishedDate.toLocaleTimeString(undefined, {
                timeStyle: "short",
              })}
            </Text>
            <Text color="brand">{name}</Text>
            <Text size="small">
              Por: <em>{account.name}</em>
            </Text>
          </Box>
        </Info>
      </StyledAnchor>
    </Wrapper>
  );
};

export default VideoCard;
