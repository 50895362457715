import { Anchor, Box, Carousel, Text } from "grommet";
import { useEffect, useState } from "react";
import config from "./config";
import styled from "styled-components";
import ContentCard from "../../components/ContentCard";
import EventCard, { Event } from "../../components/EventCard";
import VideoCard, { VideoType } from "../../components/VideoCard";

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
  }
`;

const Home = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [videos, setVideos] = useState<VideoType[]>([]);

  useEffect(() => {
    fetch(config.events.apiEndpoint)
      .then((response) => response.json())
      .then((data) => setEvents(data || []));

    fetch(config.videos.apiEndpoint)
      .then((response) => response.json())
      .then(({ data }) => setVideos(data || []));
  }, []);

  return (
    <>
      <Box
        pad={{ bottom: "xlarge" }}
        justify="between"
        gap="medium"
        width="large"
      >
        <Text size="small">{config.description.pt}</Text>
        <Text size="small">{config.description.en}</Text>
      </Box>

      <Box gap="small" pad={{ bottom: "medium" }}>
        <div>
          <Text size="large">{config.events.name} </Text>
          <Anchor href={config.events.url} target="_blank">
            {config.events.seeMore} {">"}
          </Anchor>
        </div>
        <GridWrapper>
          {events.map((eventItem) => (
            <EventCard key={eventItem.uuid} {...eventItem} />
          ))}
        </GridWrapper>
      </Box>

      <Box pad={{ bottom: "large" }}>
        <div>
          <Text size="large">{config.videos.name} </Text>
          <Anchor href={config.videos.url} target="_blank">
            {config.videos.seeMore} {">"}
          </Anchor>
        </div>

        <Box pad={{ bottom: "medium", top: "medium" }}>
          <Carousel play={4000}>
            {videos.map((video) => (
              <VideoCard key={video.uuid} {...video} />
            ))}
          </Carousel>
        </Box>
      </Box>

      <Box gap="small" pad={{ bottom: "medium" }}>
        <Text size="large">{config.socialNetworks.name}</Text>
        <GridWrapper>
          {config.socialNetworks.items.map((item) => (
            <ContentCard key={item.title} {...item} />
          ))}
        </GridWrapper>
      </Box>

      <Box gap="small" pad={{ bottom: "medium" }}>
        <Text size="large">{config.tools.name}</Text>
        <GridWrapper>
          {config.tools.items.map((item) => (
            <ContentCard key={item.title} {...item} />
          ))}
        </GridWrapper>
      </Box>

      <Box align="center" pad={{ bottom: "large" }}>
        <Text size="large" margin={{ bottom: "small" }}>
          {config.footer.name}{" "}
        </Text>
        <Box flex direction="row">
          {config.footer.items.map(({ href, name }, index) => (
            <>
              {index > 0 && (
                <Box pad={{ left: "small", right: "small" }}>{" | "}</Box>
              )}
              <Anchor target="_blank" href={href}>
                {name}
              </Anchor>
            </>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Home;
